import React, { useState } from "react";

import classNames from "classnames";

const TopBanner = () => {
  const [hidden, setHidden] = useState(false);

  const handleHide = () => {
    setHidden(!hidden);
  };
  return (
    <div
      className={classNames({
        " w-full": true,
        "bg-sail": true,
        hidden: hidden,
      })}
    >
      <div className="flex sm:flex-row flex-col justify-between items-center py-8 mx-auto w-11/12 sm:space-x-8 space-x-0 sm:space-y-0 space-y-8">
        <div className="sm:text-start text-center flex sm:flex-row flex-col mx-auto w-2/3 items-center sm:space-x-8 space-x-0 sm:space-y-0 space-y-8">
          <p className="text-xl leading-6 text-gray-900">
            <span className="bg-teal-accent-400 text-gray-900 p-2 px-4 text-center rounded-full">
              Novità!{" "}
            </span>
          </p>
          <p className="text-2xl text-gray-900">
            Prendi la patente moto SENZA ESAME! Con Autoscuola Faro!
          </p>
          <a
            href="/patente-moto-senza-esame"
            className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-xl font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Clicca qui! <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
        <div className="">
          <button
            onClick={handleHide}
            type="button"
            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          >
            <span className="sr-only">Dismiss</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
