import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

const PageNavigator = (props) => {
  const { 
    link, 
    text, 
    bgColor="white", 
    bgIntensity=null,
  }  = props;
  
  return (
    <div className={`bg-${bgColor}-${bgIntensity} flex flex-wrap flex-row px-12 py-6 justify-center sm:justify-start w-full`}>
      <Link href={link} className={`h-10 w-72 text-lg text-start text-blue-accent-700 items-center`}>
        <FontAwesomeIcon
          icon={faArrowLeftLong}
          className={`w-8 mr-2 text-lg fa-solid h-5 text-text-blue-600`}
        />
        {text}
      </Link>
    </div>
  )
};

export default PageNavigator;
