import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";

const LeftIntestazioneTemporanea = () => {
  return (
    <div className="sm:w-full md:w-1/3 lg:w-2/5 sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left mx-auto">
      <div className="sm:mb-4 mb-4 px-8 py-2">
        <h2 className="text-center text-3xl font-roboto font-bold tracking-tight text-gray-900">
          Prezzo
        </h2>
      </div>
      <div className="flex-col space-y-8">
        {/* Price Item for intestazione temporanea */}
        <div className="sm:w-full text-center">
          {/* Image */}
          <div className="sm:order-none order-first h-40 w-40 inline-flex items-center justify-center rounded-full bg-blue-accent-700 text-white flex-shrink-0">
            <p className="leading-relaxed font-bold text-4xl text-white">
              100
              <FontAwesomeIcon
                icon={faEuroSign}
                className="fa-solid flex-shrink-0 h-8 w-8 text-white"
              />
            </p>
          </div>
          {/* Description */}
          <p className="text-center text-gray-700 text-xl mt-4">
            Intestazione temporanea
          </p>
        </div>
      </div>
    </div>
  );
};

const RightIntestazioneTemporanea = () => {
  return (
    <div className="w-full sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left mx-auto">
      <div className="">
        <div className="text-2xl text-black my-4">
          <p>
            L'intestazione temporanea di autoveicoli, motoveicoli e rimorchi è obbligatoria in favore di un soggetto diverso dall'intestatario del veicolo che ne usufruisca per un periodo superiore a 30 giorni naturali e consecutivi.
          </p>
          <p>
            Per l'intestazione temporanea sono necessari i seguenti documenti:
          </p>
        </div>
        <ul className="list-disc pl-6">
          <li className="text-gray-700 text-xl">Documento d'identità dante causa</li>
          <li className="text-gray-700 text-xl">Documento d'identità avente causa</li>
          <li className="text-gray-700 text-xl">Carta di circolazione del veicolo in oggetto</li>
        </ul>
        <p className="text-2xl text-black mt-4">
          A fine procedimento verrà rilasciato un tagliando da apporre sulla carta di circolazione indicante utilizzatore e durata del comodato d'uso.
        </p>
      </div>
    </div>
  );
};

export { LeftIntestazioneTemporanea, RightIntestazioneTemporanea };
