import React from 'react'
import Layout from '../../components/layout/Layout';
import PageNavigator from '../../components/layout/PageNavigator';
import ContentPratica from '../../components/ContentPratica';
import { LeftIntestazioneTemporanea, RightIntestazioneTemporanea } from '../../components/pratiche-auto/intestazione/Intestazione';

const IntestazioneTemporanea = () => {
    return (
        <Layout>
            <PageNavigator link="/pratiche-auto" text="Pratiche auto" />
            <ContentPratica id="intestazione-temporanea" title="Intestazione Temporanea">
                <LeftIntestazioneTemporanea />
                <RightIntestazioneTemporanea/>

            </ContentPratica>
        </Layout>
    )
}

export default IntestazioneTemporanea
