import React from "react";
import Footer from "./Footer";
import CustomHeader from "./CustomHeader";
import Banner from "./Banner";
import { ReactCookieConsent } from "./ReactCookieConsent";
import TopBanner from "../home-page/TopBanner";

const Layout = ({ children }) => {
  return (
    <>
      {/* <Banner /> */}
      <TopBanner />
      <CustomHeader />

      <main className="text-gray-900">{children}</main>
      <Footer />
      <ReactCookieConsent />
    </>
  );
};

export default Layout;
